body, html {
  background-color: #eee;
}

#root {
  background-color:#eee;
}



.App {
  
  text-align: center;

  min-height: 100vh;
  color:#1d1e21; 
  font-size: calc(10px + 2vmin);
  background-color:#eeeeee;
  
  margin:0 auto;
  position:relative;
  padding-top:40px;
  padding-bottom: 75px;
  padding-left:5px;
  padding-right:5px;
}

.App-header {
  padding-top:10px;
  min-height: 30px;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
 
}


a {
  color:#439aae
}

a:hover{
  text-decoration:none;
  color: #f9a521;
}

p{
  color:#1d1e21; 
  font-size: 12px;
}


h1, h2, h3 {
  font-weight:bold;
  margin-bottom: 10px;
  color:#1d1e21;
}

h1{
  font-size: calc(16px + 2vmin);
}

h2{
  margin-top:50px;
  font-size: calc(14px + 2vmin);
}

h3 {
  font-size: calc(12px + 1vmin);
}

h4 {
  font-size: calc(10px + 1vmin);
  text-align:left;
}

h5{
  font-size: 12px;
  text-align:left;
  margin-bottom:5px;
}

p{
  font-size: 12px;
  
}

button.MuiButtonBase-root.MuiFab-root{
  border-radius: 30px;
  position:fixed;
  right:20px;
  bottom:20px;
  z-index:50;
}

button.MuiButtonBase-root, span.MuiButtonBase-root {
  padding:5px 10px;
  border-radius: 5px;
  
}

.dashboard-event-table-container{
  width: 100%;
  min-height: 100px;
}

.data-table-header{
   background-color: #ccc;
   color:#1d1e21;
}

.data-table{
  position:relative;
}

.edit-form{
  
  margin:0 auto;
}

.form-tabs{
  border-bottom: none;
}

.tab-panel{
  border:0px solid #ccc;
  text-align:left;
  padding: 10px;
  background-color:#fff;
}

.form-image-upload{
  margin-bottom: 15px;
}
.form-image-upload hr{
  margin: 10px 0px;
}

.form-image-upload-label{
  font-size:14px;
  font-weight:bold;
  display:block;
  margin-bottom:10px;
}

.product-list-item .MuiImageListItemBar-subtitle, .product-list-item .MuiImageListItemBar-title{
  overflow:visible !important;

}
.form-input-field.product-item-input {
  width: 50%;
}

.form-input-field.white-input-field label{
  color:#fff;
  font-size: 11p5;
}

.form-input-field.white-input-field .MuiOutlinedInput-notchedOutline{
   border-color:#fff;
}

.form-input-field.white-input-field .MuiOutlinedInput-root{
  color:#fff;
  font-size:11pt;
}


.form-input-field.MuiTextField-root, .form-input-field.MuiFormControlLabel-root, .form-input-field.MuiFormControl-root {
  margin-bottom:15px;
}

.form-input-field .MuiButtonBase-root{
  padding:9px;
  position:absolute;
  border-radius:50%;

}

.form-input-field .MuiAutocomplete-endAdornment .MuiButtonBase-root, .form-input-field .MuiInputAdornment-root .MuiButtonBase-root {
    position:relative;
}

 .MuiMenuItem-root.MuiButtonBase-root {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    color: inherit;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    min-height: 48px;
    padding: 6px 16px;
    box-sizing: border-box;
    white-space: nowrap;
}


.add-button-wrapper{
  text-align:right;
  margin-bottom:15px;
  padding-right:15px;
  display:inline-block;
  width: 100%;
}

.filter-wrapper{
  text-align:left;
  margin-bottom:15px;
  padding-left:15px;
  display:inline-block;
  width:45%;
}

.input-row{
  margin-bottom:10px;
  text-align: left;

}


.input-row.form-actions{
  text-align: center;
  align-items: center;
  align-content: center;
  margin-top:20px;
}

.field-label{
  display:block;
  text-align: left;
  font-size:14px;
  font-weight:bold;
  text-transform: uppercase;
  margin-bottom:5px;
}

.filter-label {
  display:inline-block;
  text-align: right;
  font-size:14px;
  font-weight:bold;
  text-transform:uppercase;
  margin-right:10px;
}

.file-upload-label{
  background-color:#439aae;
  color:#fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.textfield{
  border: 1px solid #ababab;
  background-color: #fafafa;
  margin-bottom:10px;
  padding:5px 10px;
  height: 40px;
  line-height:30px;
  font-size: 11px;
  width: 100%;
  
}

.textarea {
   border: 1px solid #ababab;
   background-color:#fafafa;
   margin-bottom:10px;
   padding:5px;
   font-size:  12px;
   width: 100%;
}

.image-preview{
  max-height: 100px;
  max-width:100%;
  box-shadow: 2px 2px 5px #666;
  
}

.image-preview-wrapper{
  width: auto;
  min-height:20px;
  margin-top:15px;
  margin-bottom: 15px;
  align-content: flex-start;
  text-align: left;
  align-items: flex-start;
}

.image-preview-wrapper a{
  font-size: 14px;
  font-weight:bold;
}

.delete-image{
  position:relative;
  left:-35px;
  z-index:100;
}





.form-error {
  text-align:center;
  font-size:12px;
  color:#dd0000;
  
  width: 80%;
  margin: 5px auto 10px auto;
  margin-bottom:10px;
}



.header-content{
  display:flex;
  flex-direction: "row";
  width:100%;
  
  

}

.header-menu {
  flex:1;
  text-align:left;
  align-items: flex-start;
}

.header-menu .MuiButtonBase-root{
  padding: 8px 16px;
}

.app-header-logo{
  flex:1;
    text-align:center;
    align-items: center;
    width:100%;
 
    max-height: 30px;
    margin: 0px auto;
    text-align: center;
    z-index:10;
}

.app-header-logo img.app-logo {

max-height: 30px;
}

.app-header-logout{
    flex:1;
    text-align:right;
    align-items: flex-end;
    padding-right:15px;
}

.App-logo {
  height: 25px;
  margin: 3px auto;
  padding-bottom:10px;
}


.stat-item .MuiTypography-body1, .order-item .MuiTypography-body1{
  font-size:13px;
  font-weight:bold;
}

.stat-item .MuiTypography-body2, .order-item .MuiTypography-body2{
  font-size:12px;
}


.floating-header{
  display:block;
  position:absolute;
  left:0;
  top:0;
  z-index:1000;
  opacity: 1;
  width: 100%;
}


.App-link {
  color: #61dafb;
}


.fab{
  position:absolute !important;
  bottom:20px !important;
  right: 20px !important;
  z-index:1500;
}

.tabs-container{
  width:100vw;
  height:65px;
  display:flex;
  position: fixed;
  bottom:10px;
  left:0;
}

.event-chat-log{
  height: 330px;
  overflow-y: auto;
}

.event-chat-log .event-chat-item{
  
  padding: 3px 10px;
}

.event-chat-log .MuiListItemIcon-root{
  min-width:20px;
  width: 25px;
}

.event-chat-log .event-chat-item .sender{
  font-size: 12px;
  font-weight:bold
}

.event-chat-log .event-chat-item .senderCheckMark{
  margin-left:3px;

}

.event-chat-log .event-chat-item .content{
  font-size:12px;
  font-weight:normal;
}

.loading_wrapper{
   position:fixed;
   z-index: 100;
   width:100vw;
   height:100vh;
   display:block;
   align-content: center;
   justify-content: center;
   text-align:center;
    opacity: 0.3;
    background-color: #333;;
   top:0;
   left:0;
   padding-top: 45vh;
}

.event-orders .order-item{
  padding: 0 16px;
  margin-bottom:5px;
}

.hidden {
  display:none;
}


.auction-item{
  border-bottom: 1px solod #ccc;
  padding-bottom:10px;
  margin-bottom:20px;
  display:block;
}

.auction-item p{
  margin-bottom:5px;
}

.auction-time-countdown.MuiTypography-body1{
  font-size: 30px;
  font-weight:bold;
  color:#eee;
  padding: 5px 10px;
  background-color: #333;
  margin-left:10px;
  margin-right:10px;
  width: auto;
  text-align:center;
  margin-top:10px;
  margin-bottom:10px;
  width: auto;
  display:inline-block;
}

.list-item {
  background-color:#fafafa;
}

.live-auction-bids {
  width: 100%;
  max-width: 360px;
  background-color: #dedede;
}


.event-management-section{
  align-items: flex-start;
  text-align: left;
  padding:10px;
}

.event-management-section h3{
  text-align:center;
  margin-top:5px;
  margin-bottom:10px;
}

.event-management-section .livestream-buttons{
   flex-direction: row;
   display:flex;
   align-content: center;
   
}

.event-management-section .livestream-button, .event-management-section .localstream-button,.event-management-section  .live-button {
  margin-right:10px;
}

.event-management-section .event-chat-log{
    max-height: 330px;
    overflow-y: scroll;
    margin-bottom:10px;
}

.event-management-section .event-chat-log .message{
  font-size: calc(8px + 1vmin);
  margin-bottom:3px;
  padding-top:0;
  padding-bottom:0;
}


.event-management-section .event-chat-log .message .sender {
  color:#f9a521;
}



.event-management-section .event-chat-log .message.deleted .sender {
  color:#f9a521;
  text-decoration: line-through;
}

.event-management-section .event-chat-log .message.deleted .content {
  text-decoration: line-through;
}


.event-management-section .management-buttons{
  width: 100%;
  flex-direction:row;
  display:flex;
  align-content:center;
  text-align: right;
  align-items:flex-end;
}



.event-new-message .chat-input{
  border:none;
  background-color: #fafafa;
  width:80%;
  float:left;
  line-height:20px;
  outline: none;;
}

.event-new-message .chat-input:focus{
  outline: none;;
}

.event-new-message .chat-send-button{
  border-radius:50px;
  border:1px solid #717171;
  padding:3px;
  color:#717171;
  float:right; 
}

.grid-product-item{
  text-align: center;
  justify-content:center;
  align-items: center;
  position:relative;
}

.grid-product-item .product-avatar{ 
    margin-bottom:5px;
    margin-left: auto;
    margin-right: auto;
}

.product-list-card{
  text-align:center;
}

.grid-product-item .product-name .MuiTypography-h5, .product-list-card .product-name{
  font-size: 12px;
  font-weight:bold;
  color: #f9a521;
  text-align:center;
}

.product-image{
  height: 40px;
  width:100%;
}

.live-product-item-price{
  font-size:16px;
  color:#fff;
}

#product-dnd-grid, #auction-product-grid{
  max-width: 400px;
}

.MuiCardContent-root.product-list-card{
  padding:5px;
  font-size:12px;
}

.product-action-label{
  font-size: 12px;
  font-weight:bold;
}

.event-product-list{
  width: 250px;
}

.event-product-image{
  height: 150px;
  width:100%;
}


.product-card-name .MuiTypography-h5 {
  font-size:14px;
  font-weight:bold;
  text-align:left;
}



.MuiCardActions-root .product-card-switch {
  align-self:right;
}

.streams{
  position:relative;
  width:100%;
  height:350px;
}

.streams video{
  width:100%;
  height:100%;
  object-fit: cover;
  background-color:#ababab;
}



.streams img.video-placeholder{
  object-fit: cover;
}

.streams .video-placeholder{
    width:100%;
    height: 350px;
    position:absolute;
    top:0;
    left:0;
    z-index:100;
}


.video-placeholder-wrapper{
  position:absolute;
  width:100%;
  height:350px;

  top:0;
  left:0;
  z-index:10;
  align-content: center;
  align-items: center;
  text-align:center;
  opacity: 1;
  animation: fadeinout 5s linear infinite;
}

@keyframes fadeinout {
  0%, 100%{ opacity: 0}
  50% { opacity: 1}
}

.video-placeholder-logo{
  max-width:45px;
  margin-top:100px;
  align-self: "center";
  
}

.video-placeholder-text{
  font-size:14px;
  color:#717171;

}

.streams .stream-player {
    width:100%;
    height: 350px;
    position:absolute;
    top:0;
    left:0;
    z-index:99;
}

.order-note{
  margin-bottom:5px;
  padding:5px;
  background-color:#eee;
  border-radius: 8px;
}

.order-note .note-content{
    font-size: 12px;
    font-weight:bold;
}

.order-note .note-date{
  font-size: 9px;
  color:#717171;
  font-style: italic;
}

.streamStats {
  position:absolute;
  bottom:10px;
  right: 10px;
  z-index:1000;
  padding:  8px;
  font-size:12px;
  color:#fff;
  background-color:#333;
  display:none;
}



.event-stats p {
  font-size: 14px;
  font-weight:normal;
}

.fab-secondary{
  right: 120px !important;
}

.fab-container{
  width: 100%;
  position: absolute;
  bottom:0px;
  left:0;
  height:50px;

}

.fab{
  position:absolute !important;
  bottom:10px !important;
  right: 10px !important;
  z-index:1500;
  width:30%;
}


.fab.mute{
  position:absolute !important;
  bottom:10px !important;
  left: 10px !important;
  width:30%;
  z-index:1500;
}

.fab.flip{
  position:absolute !important;
  bottom:10px !important;
  right: 10px !important;
  width:30%;
  z-index:1500;
}

.show-button.MuiButton-root{
  font-size:11px;
  max-width:140px;
 
}

.hide-button.MuiButton-root{
  font-size:11px;
  max-width:140px;
 
}

.drawer-header {
  height: 50px;
  display:block;
  margin-bottom:10px;
  background-color: #333;
  text-align: center;
  padding:15px;
  width:220px;
}

.drawer-header img{
  margin-top:10px;
  width: 50%;
}



.product-list{
    height: auto;
    scroll-behavior: auto;
    overflow-y: auto;

}

#select-product-list{
  
  max-width: 1200px;
  width: 100%;
}

.product-card-field{
  display:inline-block;
}



.product-card-label, .product-card-label .MuiTypography-h5{
  font-size:12px;
  display:block;
}


.product-card-input{
  width: 80px;
  display:block;
}

.product-card{
  margin-top:5px;
  margin-bottom:10px;
}

.product-divider{
  margin-top:5px;
  margin-bottom:5px;
}

.accordion-content, .MuiAccordionDetails-root.accordion-content{
  display:block !important;
}

.accordion-content .accordion-buttons{
  margin-top:15px;
  margin-bottom:15px;
  text-align:left;
}

.auction-bid-item{
  margin-bottom: 10px;
  font-size: 11px;
  
}

.inventory-size{
  width:50%;
}

.inventory-qty{
  width:25%;
}

.inventory-btn{
  width:25%;
}


.table-action-row{
  width: 80%;
  max-width:500px;
  margin-left:0;
  margin-right:0;
  text-align:left;
  margin-bottom:10px;
}

.table-action-row .form-input-field{
  width: 50%;
}

.dialog-field-row{
  display:block;
  width:70vw;
  max-width:400px;
  margin-bottom:15px;
}

.dialog-field-label {
    width: 25%;
    display:inline-block;
    margin-right: 5%;
    font-weight:bold;
}

.dialog-field-input {
  width: 70%;
  display:inline-block;
  clear:right;
  
}

.blocked-user{
  text-decoration:line-through;
  color:#666;
}

.application-table .user-display{
  display:block;
}

.application-table .user-display .user-avatar{
  display:inline-block;
  margin-right:10px;
}

.application-table .user-display .user-displayname{
  display:inline-block;
}

.dialog-avatar {
  display:inline-block;
  margin-right:10px;
}

.dialog-displayname{
  display:inline-block;
}

.recording-user-item.MuiListItem-root{
  display:block;
}

.csv-result{
  font-size:  14px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 25px;
}